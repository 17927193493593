export const APP_PREFIX = '/';
export const FULLPAGE_PREFIX = '/';

const PATH: { [path: string]: string } = {
    MAIN: '/',
    ABOUT_US: '/about-us',
};

export const PATH_MAP = Object.keys(PATH).reduce<{ [key: string]: string }>(
    (map, key) => ({ ...map, [PATH[key]]: key }),
    {},
);

export default PATH;

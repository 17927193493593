import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import './index.scss';
import { ConfigProvider } from 'antd';
import { getMessage } from 'assets/i18n';
import AppContextProvider, { GlobalContext } from 'store/appStore';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import Router from 'router/router';
import reportWebVitals from './reportWebVitals';

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyC8dOu2W-xGgahKq3rjI2mFGpXkSzR90Zs',
    authDomain: 'xtrend-web.firebaseapp.com',
    projectId: 'xtrend-web',
    storageBucket: 'xtrend-web.appspot.com',
    messagingSenderId: '229398939578',
    appId: '1:229398939578:web:7edd6ebb95acc5e2107f83',
    measurementId: 'G-HWVPCRJ481',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const AppWrapper: React.FC = () => {
    const { lang } = useContext(GlobalContext);
    return (
        <IntlProvider
            locale={lang}
            messages={getMessage(lang)}
            defaultLocale="tw"
        >
            <Router />
        </IntlProvider>
    );
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    <React.StrictMode>
        <ConfigProvider theme={{ token: { fontSizeHeading3: 30 } }}>
            <AppContextProvider>
                <AppWrapper />
            </AppContextProvider>
        </ConfigProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

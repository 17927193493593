import React from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import flat from 'flat';
import zh_TW from './zh-TW.json';
import en_US from './en-US.json';

const langFiles: { [key in Language]: any } = {
    'en-US': flat(en_US),
    'zh-TW': flat(zh_TW),
};

export const detectBrowserLanguage = () => {
    let lang;

    if (localStorage.getItem('language')) {
        lang = localStorage.getItem('language');
    } else {
        lang = navigator.language ? navigator.language : 'zh-TW';
        localStorage.setItem('language', lang);
    }

    return (lang as Language) || 'zh-TW';
};

export const getMessage = (lang: Language): any =>
    langFiles[lang] ? langFiles[lang] : langFiles['zh-TW'];

const cache = createIntlCache();

export const intl = () =>
    createIntl(
        {
            locale: detectBrowserLanguage(),
            messages: getMessage(detectBrowserLanguage()),
        },
        cache,
    );

import React, { lazy, Suspense } from 'react';
import { Navigate, Route, RouteProps } from 'react-router-dom';
import PATH, { APP_PREFIX } from './pathConstants';

export const WaitingComponent = (Component: any) =>
    function (props: any) {
        return (
            <Suspense fallback={<div>Module loading....</div>}>
                <Component {...props} />
            </Suspense>
        );
    };

const EmptyPage = () => (
    <>Opps... Something went wrong. Please check your url path again.</>
);

/*
  Layout
*/
const App = WaitingComponent(lazy(() => import('containers/App/App')));

const routes = () => (
    <>
        <Route path={APP_PREFIX} element={<App />}>
            {AppRoutes()}
        </Route>
        <Route path="*" element={<Navigate to={APP_PREFIX} />} />
    </>
);

/*
  APP Routes
*/
const Main = WaitingComponent(lazy(() => import('containers/Main/Main')));
const AboutUs = WaitingComponent(
    lazy(() => import('containers/AboutUs/AboutUs')),
);
const appRoutes: RouteProps[] = [
    { path: PATH.MAIN, element: <Main /> },
    { path: PATH.ABOUT_US, element: <AboutUs /> },
];

export const AppRoutes = () =>
    appRoutes.map((config) => (
        <Route key={`route_${config.path}`} {...config} />
    ));

export default routes;

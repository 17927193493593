import React, { useState, useMemo } from 'react';
import { detectBrowserLanguage } from 'assets/i18n';

const browserlang = detectBrowserLanguage();

interface GlobalContextType {
    lang: Language;
    setLang: (s: Language) => any;
}

export const GlobalContext = React.createContext<GlobalContextType>(
    {} as GlobalContextType,
);

type AppcontextProps = {
    children?: React.ReactNode;
};

const AppContextProvider: React.FC<AppcontextProps> = ({ children }) => {
    const [lang, setLang] = useState<Language>(browserlang);

    const value = useMemo(
        () => ({
            lang,
            setLang,
        }),
        [lang, setLang],
    );

    return (
        <GlobalContext.Provider value={value}>
            {children}
        </GlobalContext.Provider>
    );
};

export default AppContextProvider;
